<template>
   <div class="main-view">
    <el-radio-group v-model="table.params.type" @change="handleTabs" style="margin-bottom:10px;">
      <el-radio-button :label="2">师傅端用户</el-radio-button>
      <el-radio-button :label="1">需求端用户</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="姓名:" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="注册号码:" prop="account">
          <el-input clearable v-model="table.params.account" placeholder="请输入注册号码"></el-input>
        </el-form-item>
        <el-form-item label="注册时间:" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            >
          </el-date-picker>
        </el-form-item>
        <!-- 师傅端有工种类型、身份类型查询条件 -->
        <template v-if="table.params.type == 2">
          <el-form-item label="工种类型:" prop="kinds">
            <el-select
              v-model="table.params.kinds"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in setKinds"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份类型:" prop="identity">
            <el-select
              v-model="table.params.identity"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in sfType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item label="是否实名:" prop="is_real">
          <el-select v-model="table.params.is_real" clearable placeholder="请选择">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在区域:" prop="area_arr" v-if="cur_area == 0">
          <el-cascader
            style="width:100%;"
            v-model="table.params.area_arr"
            :options="areaArr"
            :props="{ checkStrictly: true, emitPath: false }">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="table.params.type === 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:sex="{row}">
        <span>{{row.sex === 0 ? '未填' : (row.sex === 1 ? '男' : '女')}}</span>
      </template>
      <template v-slot:is_real="{row}">
        <span>{{row.is_real === 1 ? '是' : '否'}}</span>
      </template>
      <template v-slot:status="{row}">
        <span>{{row.status === 1 ? '正常' : '异常'}}</span>
      </template>
      <template v-slot:identity="{row}">
        <span>{{toStr({0:'未填',1:'学生',2:'自由职业',3:'宝妈',4:'全职'},row.identity)}}</span>
      </template>
      <template v-slot:source="{row}">
        <span>{{row.source ? toStr({1:'微信', 2:'IOS', 3:'安卓', 4:'支付宝',5:'IOT',6:'设备端'}, row.source) : '' }}</span>
      </template>
      <template v-slot:is_perfect="{row}">
        <!-- is_perfect 1：完善 0:未完善 -->
        <span>{{row.is_perfect == 1 ? '已完善' : '未完善' }}</span>
      </template>
      <template v-slot:area="{row}">
        <span>{{row.city_str}}{{row.area_str}}</span>
      </template>
      <template v-slot:kinds="{row}">
        <template v-if="row.kinds.length > 0">
          <span v-for="item in row.kinds" :key="item">{{item ? `【${item}】` : ''}}</span>
        </template>
        <template v-else>
          <span></span>
        </template>
      </template>
      
      <template v-slot:action="{row}">
        <el-button type="text" icon="el-icon-view" @click="showDetail(row, table.params.type)">查看</el-button>
        <!-- <el-button type="text" @click="showEdit(row, table.params.type)" >{{row.status === 1 ? '封号' : '恢复'}}</el-button> -->
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
    <detail ref="detail"></detail>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import Detail from './components/Detail.vue';
import { mixinTable } from '@/mixins/table.js';
import { getStorage } from '@/storage/index.js'
import { mapGetters } from "vuex";
export default {
  name: 'UserList',
  components: {
    VTable,
    Edit,
    Detail,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(["setKinds"]),
  },
  data() {
    return {
      field: [
        { name: "nickname", label: "姓名",  width: "160", hidden: false },
        { name: "sex", label: "性别", hidden: false },
        { name: "area", label: "所在地区", hidden: false },
        { name: "account", label: "注册号码", hidden: false },
        { name: "create_time", label: "注册时间", hidden: false },
        { name: "source", label: "注册来源", hidden: false },
        { name: "publish_count", label: "发布次数", hidden: false },
        { name: "is_real", label: "实名认证", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "action", label: "操作", width: "200", hidden: false }
      ],
      field2: [
        { name: "nickname", label: "姓名",  width: "160", hidden: false },
        { name: "sex", label: "性别", hidden: false },
        { name: "kinds", label: "擅长工种", hidden: false },
        { name: "area", label: "所在地区", hidden: false },
        { name: "identity", label: "身份类型", hidden: false },
        { name: "account", label: "注册号码", hidden: false },
        { name: "create_time", label: "注册时间",  width: "160", hidden: false },
        { name: "source", label: "注册来源", hidden: false },
        { name: "is_real", label: "实名认证", hidden: false },
        { name: "is_perfect", label: "是否完善名片", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "action", label: "操作", width: "200", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          type: 2, // 类型 1:雇主 2:工人
          name: '',
          account: '',
          date: [],
          start_time: '',
          end_time: '',
          kinds:'',
          area_arr: '',
          province: '',
          city: '',
          area: '',
          is_real: '', // 是否实名
          identity:'', // 身份类型
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      sfType: [
        {id:0,name:'未填'},
        {id:1,name:'学生'},
        {id:2,name:'自由职业'},
        {id:3,name:'宝妈'},
        {id:4,name:'全职'},
      ],
      areaArr: [],
      cur_city: getStorage("city"),
      cur_area: getStorage("area"),
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  methods: {
    getTable() {
      let _date = this.table.params.date;
      this.table.params.start_time = _date ? _date[0] : '';
      this.table.params.end_time = _date ? _date[1] : '';
      let _area_arr = this.table.params.area_arr;
      let _params = {
        type: this.table.params.type, // 类型 1:雇主 2:工人
        name: this.table.params.name,
        account: this.table.params.account,
        start_time: this.table.params.start_time,
        end_time: this.table.params.end_time,
        kinds: this.table.params.kinds,
        is_real: this.table.params.is_real,
        identity: this.table.params.identity,
        area: _area_arr,
        page: this.table.params.page,
        count:  this.table.params.count
      }
      this.$http.get('/government/user/list', { params:_params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 根据市id查询区县
    getArea() {
      this.$http.post('/common/district/all',{pid: this.cur_city}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    showEdit(row, userType) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row, userType)
      }
      dom = null
    },
    // 查看
    showDetail(row, userType) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row, userType)
      dom = null
    },
    // 关闭
    handleClose() {
      this.$confirm("是否关闭此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    delete(rowid) {
      this.$http.post('/government/article/delete', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    }
  }
}
</script>